import '../styles/hero-section.scss';

import Img, { FluidObject } from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

export interface HeroSectionProps {
  titleId?: string;
  headingId?: string;
  paragraphId?: string;
  backgroundImage?: FluidObject;
}

export const HeroSection: React.FC<HeroSectionProps> = ({
  titleId,
  headingId,
  paragraphId,
  backgroundImage
}) => {
  const intl = useIntl();

  return (
    <section
      id="hero-section"
      className="hero-section section-light full-height-section centered"
    >
      {backgroundImage ? <Img fluid={backgroundImage} /> : null}

      <div className="container centered">
        <div className="section-headings-container">
          {titleId && (
            <p className="text-uppercase text-small">
              {intl.formatMessage({ id: titleId })}
            </p>
          )}
          {headingId && <h1>{intl.formatMessage({ id: headingId })}</h1>}
          {paragraphId && (
            <strong>
              <p>{intl.formatMessage({ id: paragraphId })}</p>
            </strong>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
