import React from 'react';

const MontageSvg: React.FC = () => (
  <svg
    enableBackground="new 0 0 512.042 512.042"
    viewBox="0 0 512.042 512.042"
    xmlns="http://www.w3.org/2000/svg"
    width="100px"
    height="100px"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <path d="m499.261 84.264c-2.809-7.233-8.899-12.48-16.291-14.036-7.555-1.595-15.448.841-21.112 6.506l-52.817 52.816-25.79-25.79 56.174-56.174c5.848-5.848 8.358-13.992 6.718-21.786-1.604-7.62-7.017-13.898-14.477-16.795-49.024-19.044-104.726-7.321-141.908 29.86-19.577 19.577-32.359 44.296-36.976 71.5-.987 4.627-1.943 9.541-2.954 14.738-5.906 30.358-13.256 68.14-34.807 89.689l-192.957 192.958c-23.826 23.826-23.826 62.596 0 86.423 11.914 11.913 27.563 17.869 43.212 17.869 15.648 0 31.298-5.956 43.211-17.869l192.956-192.957c21.551-21.551 59.332-28.9 89.69-34.807 5.198-1.011 10.111-1.967 14.739-2.954 27.204-4.617 51.923-17.399 71.5-36.976 5.776-5.777 7.448-13.618 5.056-20.589 26.215-34.726 32.74-80.67 16.833-121.626zm-23.261 6.611c.881-.88 1.92-1.274 2.851-1.076.799.168 1.393.742 1.768 1.705 12.785 32.919 8.366 69.667-11.27 98.354l-46.166-46.166zm-12.771 121.463c-16.67 16.67-37.727 27.538-60.893 31.432-.146.024-.29.052-.435.083-4.508.964-9.404 1.916-14.588 2.925-33.031 6.426-74.141 14.423-100.014 40.296l-192.954 192.956c-16.027 16.029-42.108 16.028-58.138-.001-16.028-16.028-16.028-42.108 0-58.137l192.957-192.957c25.873-25.872 33.87-66.981 40.296-100.013 1.009-5.184 1.961-10.08 2.925-14.588.031-.145.059-.289.084-.436 3.891-23.164 14.76-44.221 31.432-60.892 21.53-21.531 50.367-33.007 79.74-33.007 13.707 0 27.536 2.501 40.785 7.647 1.205.468 1.927 1.232 2.146 2.271.247 1.173-.223 2.458-1.289 3.523l-63.245 63.245c-3.905 3.905-3.905 10.237 0 14.143l101.246 101.246c.036.035.067.068.094.099-.039.051-.088.104-.149.165z" />
        <path d="m347.893 124.974c-1.876-1.875-4.419-2.929-7.071-2.929s-5.196 1.054-7.071 2.929l-28.283 28.284c-3.905 3.905-3.905 10.237 0 14.143l39.932 39.933c1.875 1.875 4.419 2.929 7.071 2.929s5.196-1.054 7.071-2.929l28.284-28.284c3.905-3.905 3.905-10.237 0-14.143zm4.577 61.145-25.79-25.79 14.142-14.142 25.79 25.79z" />
        <path d="m72.345 443.888c-3.906-3.906-10.235-3.901-14.14.003-3.905 3.905-3.903 10.239.002 14.145 1.953 1.952 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929c3.905-3.905 3.905-10.237 0-14.143z" />
        <path d="m251.164 250.937-161.137 161.136c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.952 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929l161.137-161.137c3.905-3.905 3.905-10.237 0-14.143-3.906-3.904-10.236-3.904-14.142.001z" />
      </g>
    </g>
  </svg>
);

export default MontageSvg;
