import * as React from 'react';

const PlanSvg: React.FC = () => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    xmlSpace="preserve"
    width="100px"
    height="100px"
  >
    <path d="M447.229 67.855h-43.181v-43.18C404.049 11.103 392.944 0 379.379 0H64.771C51.2 0 40.097 11.103 40.097 24.675V419.47c0 13.571 11.103 24.675 24.675 24.675h43.181v43.181c0 13.571 11.098 24.675 24.675 24.675h209.729c13.565 0 32.762-7.612 42.638-16.908l68.929-64.882c9.888-9.296 17.969-28.012 17.969-41.583l.012-296.096c-.001-13.573-11.105-24.677-24.676-24.677zM107.951 92.531v333.108h-43.18c-3.343 0-6.168-2.825-6.168-6.168V24.675c0-3.343 2.825-6.168 6.168-6.168H379.38c3.337 0 6.168 2.825 6.168 6.168v43.181H132.626c-13.577 0-24.675 11.103-24.675 24.675zM441.24 416.737l-68.929 64.877c-1.412 1.327-3.251 2.628-5.281 3.867v-56.758c0-4.238 1.709-8.051 4.528-10.888 2.844-2.819 6.656-4.533 10.894-4.533h61.718c-.957 1.3-1.937 2.497-2.93 3.435zm12.145-28.111c0 1.832-.334 3.954-.839 6.168h-70.095c-18.721.037-33.89 15.206-33.928 33.928v64.024c-2.202.445-4.324.746-6.168.746H132.626v.001c-3.35 0-6.168-2.825-6.168-6.168V92.53c0-3.343 2.819-6.168 6.168-6.168h314.602c3.343 0 6.168 2.825 6.168 6.168l-.011 296.096z" />
    <path d="M379.379 154.216H200.488a9.248 9.248 0 00-9.253 9.253 9.249 9.249 0 009.253 9.253h178.891c5.108 0 9.253-4.139 9.253-9.253s-4.145-9.253-9.253-9.253zM379.379 277.59H200.488a9.248 9.248 0 00-9.253 9.253 9.249 9.249 0 009.253 9.253h178.891a9.252 9.252 0 009.253-9.253 9.251 9.251 0 00-9.253-9.253zM299.187 339.277h-98.698c-5.114 0-9.253 4.139-9.253 9.253s4.139 9.253 9.253 9.253h98.698c5.108 0 9.247-4.139 9.247-9.253s-4.139-9.253-9.247-9.253zM379.379 215.903H200.488c-5.114 0-9.253 4.139-9.253 9.253s4.14 9.253 9.253 9.253h178.891c5.108 0 9.253-4.139 9.253-9.253s-4.145-9.253-9.253-9.253z" />
  </svg>
);

export default PlanSvg;
