import * as React from 'react';

const ZoomSvg = () => (
  <svg
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 512.001 512.001"
    xmlSpace="preserve"
  >
    <path d="M430.827 86.605c-49.161-51.53-124.712-59.44-180.6-28.393-4.829 2.682-6.568 8.772-3.886 13.6 2.682 4.83 8.772 6.567 13.6 3.886 51.118-28.401 115.436-18.236 156.371 24.671 42.236 44.811 46.992 111.607 14.317 161.128-45.008 68.362-142.217 78.664-200.5 20.37-39.273-39.259-50.651-102.572-22.282-154.093 2.662-4.84.897-10.922-3.942-13.584-4.839-2.66-10.92-.897-13.584 3.942a151.08 151.08 0 00-2.754 5.324h.001c-29.088 59.294-15.854 128.301 28.418 172.556 67.148 67.162 179.31 55.53 231.345-23.507 37.317-56.559 32.739-133.655-16.504-185.9z" />
    <path d="M456.438 55.562c-74.091-74.081-194.651-74.084-268.745.001-66.628 66.638-73.801 171.115-19.542 245.856l-31.167 31.16c-10.927-5.23-24.745-3.542-34.155 5.869L14.667 426.62c-19.547 19.534-19.563 51.168-.005 70.713 19.539 19.552 51.172 19.56 70.715.002l88.175-88.164c9.406-9.408 11.1-23.226 5.868-34.154l31.163-31.17c74.758 54.271 179.227 47.079 245.855-19.54 74.084-74.093 74.084-194.651 0-268.745zM159.407 395.027L71.23 483.193c-11.719 11.728-30.689 11.739-42.424-.005-11.73-11.721-11.737-30.691.002-42.422l88.164-88.174c3.897-3.898 10.242-3.9 14.139 0l28.295 28.295c3.9 3.898 3.9 10.242.001 14.14zm7.072-35.357l-14.148-14.148 28.481-28.474a192.033 192.033 0 006.88 7.259 192.183 192.183 0 007.261 6.882l-28.474 28.481zm275.814-49.507c-66.277 66.267-173.635 66.831-240.458 0-66.743-66.736-66.346-174.099 0-240.456 66.289-66.28 174.158-66.291 240.457 0 66.285 66.294 66.285 174.163.001 240.456z" />
    <path d="M230.37 84.108c-.07-.09-.15-.17-.24-.26-3.911-3.901-10.242-3.901-14.152 0-3.901 3.911-3.901 10.242 0 14.152.09.09.17.17.26.25 3.931 3.651 10.072 3.561 13.892-.25 3.81-3.821 3.9-9.962.24-13.892z" />
  </svg>
);

export default ZoomSvg;
