import { Carousel } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useRef, useState } from 'react';

import HeroSection from '../components/hero-section';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MontageSvg from '../components/svgs/fences-page/montage';
import PlanSvg from '../components/svgs/fences-page/plan';
import TickSvg from '../components/svgs/fences-page/tick';
import ZoomSvg from '../components/svgs/fences-page/zoom';
import TilesList, { TilesListProps } from '../components/tiles-list';
import SvgLeftCircleOutlined from '../images/svgs/left-circle-arrow.svg';
import SvgRightCircleOutlined from '../images/svgs/right-circle-arrow.svg';
import { generateUniqId } from '../utils/generate-uniq-id';
import { makeFormatter } from '../utils/intl';
import CtaSection from '../components/cta-section';
import SectionPreviewPdf from '../components/section-preview-pdf';
import { FENCES_FILE_PATH_IN_STORAGE } from '../config/storage';

const CAROUSEL_SLIDES_COUNT = 3;
const CAROUSEL_AUTOPLAY_SPEED = 2500;

const SERVICES_ITEMS: TilesListProps['items'] = [
  {
    id: generateUniqId(),
    icon: <MontageSvg />,
    titleLocalizationKey: 'fences.SERVICE_1'
  },
  {
    id: generateUniqId(),
    icon: <ZoomSvg />,
    titleLocalizationKey: 'fences.SERVICE_2'
  },
  {
    id: generateUniqId(),
    icon: <TickSvg />,
    titleLocalizationKey: 'fences.SERVICE_3',
    descriptionLocalizationKey: ''
  },
  {
    id: generateUniqId(),
    icon: <PlanSvg />,
    titleLocalizationKey: 'fences.SERVICE_4'
  }
];

const FencesPage: React.FC = () => {
  const images = useStaticQuery(graphql`
    query {
      backgroundImage: file(
        relativePath: { eq: "fences-page/fences-hero.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderImage_1: file(relativePath: { eq: "fences-page/slider-1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderImage_2: file(relativePath: { eq: "fences-page/slider-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderImage_3: file(relativePath: { eq: "fences-page/slider-3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      truck: file(relativePath: { eq: "fences-page/truck.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sommer: file(relativePath: { eq: "fences-page/sommer.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schiebetorantriebe: file(
        relativePath: { eq: "fences-page/schiebetorantriebe.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drehtorantriebe: file(
        relativePath: { eq: "fences-page/drehtorantriebe.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [slide, setSlide] = useState(0);
  const carouselRef = useRef<any>(null);

  useEffect(() => {
    const carousel = carouselRef.current;

    if (carousel) {
      carousel.goTo(slide);
    }
  }, [carouselRef, slide]);

  const format = makeFormatter(useIntl(), 'fences');
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={format('SEO_TITLE')} />

      <main id="services-niche-page">
        <HeroSection
          headingId={format('HERO_HEADING')}
          paragraphId={format('HERO_PARAGRAPH')}
          titleId={'navigation.PAGE_SERVICES_TITLE'}
          backgroundImage={images?.backgroundImage?.childImageSharp?.fluid}
        />

        <section className="section-container">
          <div className="container carousel-container" data-aos="fade-up">
            <Carousel
              autoplay
              autoplaySpeed={CAROUSEL_AUTOPLAY_SPEED}
              ref={carouselRef}
              afterChange={index => setSlide(index)}
            >
              {Array.from({ length: CAROUSEL_SLIDES_COUNT }).map((_, index) => {
                const name = `sliderImage_${index + 1}`;

                return (
                  <div key={name}>
                    <Img fluid={images?.[name]?.childImageSharp?.fluid} />
                  </div>
                );
              })}
            </Carousel>

            <div
              className="carousel-button carousel-button-left"
              onClick={() =>
                setSlide(current =>
                  current === 0 ? CAROUSEL_SLIDES_COUNT - 1 : current - 1
                )
              }
            >
              <SvgLeftCircleOutlined />
            </div>

            <div
              className="carousel-button carousel-button-right"
              onClick={() =>
                setSlide(current =>
                  current === CAROUSEL_SLIDES_COUNT - 1 ? 0 : current + 1
                )
              }
            >
              <SvgRightCircleOutlined />
            </div>
          </div>
        </section>

        <SectionPreviewPdf
          filePathInStorage={FENCES_FILE_PATH_IN_STORAGE}
          buttonLabel={intl.formatMessage({ id: 'common.SEE_CATALOG' })}
          title={intl.formatMessage({ id: 'fences.SECTION_PDF_TITLE' })}
          heading={intl.formatMessage({ id: 'fences.SECTION_PDF_HEADING' })}
          paragraph={intl.formatMessage({
            id: 'fences.SECTION_PDF_PARAGRAPH'
          })}
        />

        <section className="section-container">
          <div className="container services-list">
            <TilesList items={SERVICES_ITEMS} />
          </div>
        </section>

        <section className="section-container">
          <div className="container" data-aos="fade-up">
            <h3 className="text-align-center">{format('FEATURE_1_TITLE')}</h3>
            <div className="row">
              <div className="col">
                <Img fluid={images?.truck?.childImageSharp?.fluid} />
              </div>
              <div className="col">
                <span>{format('FEATURE_1_DESC')}</span>
              </div>
            </div>
          </div>
        </section>

        <section className="section-container">
          <div className="container" data-aos="fade-up">
            <h3 className="text-align-center">{format('FEATURE_2_TITLE')}</h3>
            <div className="row reversed-column-on-mobile">
              <div className="col">
                <span>{format('FEATURE_2_DESC')}</span>
              </div>

              <div className="col">
                <Img fluid={images?.sommer?.childImageSharp?.fluid} />
              </div>
            </div>
          </div>
        </section>

        <section className="section-container">
          <div className="container" data-aos="fade-up">
            <h3 className="text-align-center">{format('FEATURE_3_TITLE')}</h3>
            <div className="row">
              <div className="col">
                <Img fluid={images?.drehtorantriebe?.childImageSharp?.fluid} />
              </div>
              <div className="col">
                <span>{format('FEATURE_3_DESC')}</span>
              </div>
            </div>
          </div>
        </section>

        <section className="section-container">
          <div className="container" data-aos="fade-up">
            <h3 className="text-align-center">{format('FEATURE_4_TITLE')}</h3>
            <div className="row reversed-column-on-mobile">
              <div className="col">
                <span>{format('FEATURE_4_DESC')} </span>
              </div>
              <div className="col">
                <Img
                  fluid={images?.schiebetorantriebe?.childImageSharp?.fluid}
                />
              </div>
            </div>
          </div>
        </section>

        <CtaSection />
      </main>
    </Layout>
  );
};

export default FencesPage;
